<template>
    <v-container>
        <h4 class="headline mt-5 text-center primary--text"><strong>The Soaltee Kathmandu</strong></h4>
        <div style="margin-left:90px">
            <v-row>

    <v-col cols="12" sm="10">
        <v-card elevation="5">
            <template>
                <!--<v-carousel cycle
                            dark
                            height="600"
                            style=" margin-top:20px;"
                            hide-delimiter-background
                            show-arrows-on-hover>
                    <v-carousel-item v-for="(slide, i) in slides" :key="i">-->
                        <v-sheet color="white" height="80%" align="center" tile>
                            <v-row class="fill-height" align="center" justify="center">
                                <div class="grey--text">
                                    <img src="../../../assets/soltihotel.jpg" width="100%" />
                                </div>
                            </v-row> 
                        </v-sheet>
                    <!--</v-carousel-item>
                </v-carousel>-->
            </template>
        </v-card>
    </v-col>
    <v-col cols="12" sm="2">
        <v-row class="mt-10">
            <v-card>
                <v-col cols="12" sm="12">
                    <v-card @click="$router.push('/hotel')" color="#006400" elevation="5">
                        <v-row>
                            <v-col cols="12" sm="5">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-avatar>
                                            <v-icon color="white" large>people</v-icon>
                                        </v-list-avatar>

                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-list-item three-line>
                                    <div>
                                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                            Customer
                                        </v-list-item-subtitle>

                                    </div>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="12">
                    <v-card @click="$router.push('/hotelEmployee')" color="#FFA500" elevation="5">
                        <v-row>
                            <v-col cols="12" sm="5">
                                <v-list-item three-line>
                                    <v-list-item-avatar>
                                        <v-icon color="white" large>directions_run</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Employee
                                            </v-list-item-subtitle>

                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-card @click="$router.push('/availableRoom')" color="#077dfa" elevation="5">
                        <v-row>
                            <v-col cols="12" sm="5">
                                <v-list-item three-line>
                                    <v-list-item-avatar>
                                        <v-icon color="white" large>room_service</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Room
                                            </v-list-item-subtitle>

                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

            </v-card>


        </v-row>
    </v-col>
</v-row>
        </div>
        

        <!--<v-row class="mt-10">
            <v-col cols="12" sm="3">
                <v-card color="#006400" elevation="5">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div>
                                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                            Total No. of Flights
                                        </v-list-item-subtitle>
                                        <v-icon color="white" x-large>flight</v-icon>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content class="text-right">
                                    <div>-->
                                        <!-- <v-list-item-subtitle class="subtitle-1 white--text" style="font-size:19px"
                                      >742,824</v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle
                                      class="white--text"
                                      style="font-size: 11px"
                                      >CHANGE TO</v-list-item-subtitle
                                    > -->
                                        <!--<v-list-item-subtitle class="white--text"
                                                              style="font-size: 26px">3200</v-list-item-subtitle>

                                        <v-list-item-subtitle class="white--text mb-5"
                                                              style="font-size: 14px">Actual</v-list-item-subtitle>
                                        <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                        <span class="white--text">3211</span><br />
                                        <span class="white--text">(2.16%)</span>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col cols="12" sm="3">
                <v-card color="#FFA500" elevation="5">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div>
                                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                            Total No. of Incoming Flights
                                        </v-list-item-subtitle>
                                        <v-icon color="white" x-large>flight_land</v-icon>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content class="text-right">
                                    <div>

                                        <v-list-item-subtitle class="white--text"
                                                              style="font-size: 26px">1700</v-list-item-subtitle>

                                        <v-list-item-subtitle class="white--text mb-5"
                                                              style="font-size: 14px">Actual</v-list-item-subtitle>
                                        <v-icon color="red" class="mt-2 mr-2">fas fa-sort-down</v-icon>
                                        <span class="white--text">1697</span><br />
                                        <span class="white--text">(0.12%)</span>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="3">
                <v-card color="#077dfa" elevation="5">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div>
                                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                            Total No. of Outgoing Flights
                                        </v-list-item-subtitle>
                                        <v-icon color="white" x-large>flight_takeoff</v-icon>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content class="text-right">
                                    <div>
                                    
                                        <v-list-item-subtitle class="white--text"
                                                              style="font-size: 26px">1511</v-list-item-subtitle>

                                        <v-list-item-subtitle class="white--text mb-5"
                                                              style="font-size: 14px">Actual</v-list-item-subtitle>
                                        <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                        <span class="white--text">1514</span><br />
                                        <span class="white--text">(4.16%)</span>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="3">
                <v-card color="#8B0000" elevation="5">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div>
                                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                            Count of Flight Type
                                        </v-list-item-subtitle>
                                        <v-icon color="white" x-large>flight_class</v-icon>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content class="text-right">
                                    <div>
                                     
                                        <v-list-item-subtitle class="white--text"
                                                              style="font-size: 26px">8</v-list-item-subtitle>

                                        <v-list-item-subtitle class="white--text mb-5"
                                                              style="font-size: 14px">Actual</v-list-item-subtitle>
                                        <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                        <span class="white--text">10</span><br />
                                        <span class="white--text">(2.16%)</span>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        

        <template>
            <v-carousel cycle
                        dark
                        height="600"
                        style=" margin-top:20px;"
                        hide-delimiter-background
                        show-arrows-on-hover>
                <v-carousel-item v-for="(slide, i) in slides" :key="i">
                    <v-sheet color="white" height="80%" align="center" tile>
                        <v-row class="fill-height" align="center" justify="center">
                            <div class="grey--text">
                                <img src="../../../assets/soltihotel.jpg" width="100%" />
                            </div>
                        </v-row>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>
        </template>-->
        <v-container>
          
            <h4 class="headline mt-10 text-center">Foreigner in Hotel</h4>
            <v-row>
                <v-col cols="12" md="12">
                    <v-card elevation="5">
                        <template>
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="barsData"
                                        :options="chartOptions"
                                        style="height: 400px; " />
                            </div>
                        </template>
                    </v-card>

                </v-col>
               
            </v-row>

           
        </v-container>
        <hr style="margin-top: 60px;" />
        <h1 class="headline mb-2 mt-5 primary--text">Hotel Customer</h1>
       
        <v-container>
          
            <h4 class="headline md-10 text-center"> List of Customers in Hotel</h4>
            <v-row>
                <v-col cols="12" md="6">
                    <template>
                        <v-card elevation="5">
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="customerData"
                                        :options="chartOptions"
                                        style="height: 400px;" />
                            </div>
                        </v-card>

                    </template>
                </v-col>
                <v-col cols="12" md="6" class="mt-15">
                    <v-card>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar color="purple">
                                    <v-icon dark>{{svgHeading}}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title><strong> Customer Name</strong> </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title class="mr-7"><strong>Date</strong></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title><strong>Days</strong></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-card style="overflow-y: scroll; max-height: 350px;">
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> Sita Kumari </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Taragaon, Kathmandu
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>2019/10/12</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>15</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> Sajin Maharjan </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Durbar Marg, Kathmandu
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>2018/10/12</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>20</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$1,600 & 3,600</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> Hari Shrestha </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Lukla-Shyangboche Marg, Khumjung
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>2018/30/12</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>35</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> Durga Mata </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Pragya Marg, Kathmandu
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>2019/40/17</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>24</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> Shyam   </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Battisputali, Kathmandu
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>2019/12/12</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>28</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> Sita Kumari </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Taragaon, Kathmandu
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>2019/10/12</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title>15</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                        </v-list>
                    </v-card>
                    <!--<button @click="$router.push('/hotel')"  class=" white--text primary " dark style="padding:5px; border-radius:5px; margin-top:10px">For More Details</button>-->
                </v-col>
                <!-- <v-col cols="12" md="6" style="margin-top: 50px">
              <template>
                <div id="chart" ml="10">
                  <apexchart
                    type="donut"
                    width="600"
                    :options="chartsOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </template>
            </v-col> -->
                <!-- <v-col cols="12" md12>
                    <h1 class="headline mb-2 grey--text">Bugets</h1>
                  </v-col> -->
            </v-row>
            <!-- <v-col cols="12" sm="5">
          <v-card
            class="mx-12 rounded-tl-xl rounded-bl-xl rounded-br-xl mt-n10"
          >
            <v-app-bar color="rgba(0,0,0,0)" flat class="ma-8">
              <h5>Your Activity</h5>
              <v-spacer></v-spacer>
              <v-btn color="primary" rounded dark depressed>Week</v-btn>
              <v-btn text>Month</v-btn>
            </v-app-bar>
            <v-progress-circular
              rotate="360"
              size="100"
              width="15"
              value="50"
              color="primary"
              class="mt-n5 ml-5 mb-2"
            >
              50
            </v-progress-circular>
            <v-progress-circular
              rotate="360"
              size="100"
              width="15"
              value="70"
              color="red"
              class="mt-n5 ml-5 mb-2"
            >
              70
            </v-progress-circular>
            <v-progress-circular
              rotate="360"
              size="100"
              width="15"
              value="80"
              color="orange"
              class="mt-n5 ml-5 mb-2"
            >
              80
            </v-progress-circular>
          </v-card>
        </v-col> -->
            <!-- </v-row> -->
        </v-container>
        <hr style="margin-top: 60px;" />
        <h1 class="headline mb-2 mt-5 primary--text">Hotel Employee</h1>
        <v-container>
            <!-- <v-row class="my-5">
        <v-col class="ml-5" cols="12" sm="3"> -->
            <!-- <h3>List of Hotel</h3>
              <v-card width="500">
                <v-card-title>Solti Hotel</v-card-title>
                <v-card-subtitle>Sub-Title</v-card-subtitle>
                <v-card-text>
                  One of the most best hotel of nepal.
                </v-card-text>
                <v-card-actions>
                  <v-btn class="blue">Details</v-btn>
                  <v-btn class="green">Location</v-btn>
                </v-card-actions>
              </v-card> -->
            <!-- <v-card class="mt-5" max-height="500" max-width="400" :elevation="24">
            <v-img
              height="200"
              class="white--text align-end"
              src="../assets/soltihotel.jpg"
            >
              <v-card-title>The Soaltee Kathmandu</v-card-title>
            </v-img>
            <v-card-subtitle class="pb-0">
              Tahachal Marg, Kathmandu 44600, Nepal</v-card-subtitle
            >
            <v-card-text class="text--primary">
              <div style="overflow-y: scroll; max-height: 180px">
                Soaltee Hotel Limited (SHL), a pioneer in Nepal's hospitality
                sector, owns the Soaltee Kathmandu, a 12-acre five-star deluxe
                hotel. A landmark in the city of Kathmandu with a rich heritage,
                the hotel is the proud recipient of several prestigious awards
                and accolades over the years. Soaltee Hotel Limited is renowned
                for its rich history as a pioneer in Nepal�s tourism industry
                with its contribution of luxurious properties and legendary
                hospitality across the region.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn class="cyan darken-2" dark>Details</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3"> -->
            <!-- <h3>List of Hotel</h3>
              <v-card width="500">
                <v-card-title>Solti Hotel</v-card-title>
                <v-card-subtitle>Sub-Title</v-card-subtitle>
                <v-card-text>
                  One of the most best hotel of nepal.
                </v-card-text>
                <v-card-actions>
                  <v-btn class="blue">Details</v-btn>
                  <v-btn class="green">Location</v-btn>
                </v-card-actions>
              </v-card> -->
            <!-- <v-card class="mt-5" max-height="500" max-width="400" :elevation="24">
            <v-img
              height="200"
              class="white--text align-end"
              src="../assets/YakandYeti.jpg"
            >
              <v-card-title>Yak and Yeti</v-card-title>
            </v-img>
            <v-card-subtitle class="pb-0"
              >Durbar Marg, Kathmandu 44600, Nepal</v-card-subtitle
            >
            <v-card-text class="text--primary">
              <div style="overflow-y: scroll; max-height: 180px">
                Yak and Yeti is a heritage hotel located in Durbar Marg, the
                thriving city center of Kathmandu Valley, in a prime location
                that is minutes walking distance from the former Royal Palace.
                Durbar marg is a commercial area with high-end shops and a
                variety of food options. Our 5 star deluxe luxury property is 6
                KM away from the Tribhuvan International Airport, about 1 KM
                from the famous tourist hub of Nepal- Thamel.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn class="cyan darken-2" dark>Details</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3"> -->
            <!-- <h3>List of Hotel</h3>
              <v-card width="500">
                <v-card-title>Solti Hotel</v-card-title>
                <v-card-subtitle>Sub-Title</v-card-subtitle>
                <v-card-text>
                  One of the most best hotel of nepal.
                </v-card-text>
                <v-card-actions>
                  <v-btn class="blue">Details</v-btn>
                  <v-btn class="green">Location</v-btn>
                </v-card-actions>
              </v-card> -->
            <!-- <v-card class="mt-5" max-height="500" max-width="400" :elevation="24">
              <v-img
                height="200"
                class="white--text align-end"
                src="../assets/hyatthotel.jpg"
              >
                <v-card-title>Hyatt Regency Kathmandu</v-card-title>
              </v-img>
              <v-card-subtitle class="pb-0"
                >Taragaon, Kathmandu 44601, Nepal</v-card-subtitle
              >
              <v-card-text class="text--primary">
                <div style="overflow-y: scroll; max-height: 180px">
                  Hyatt Regency Kathmandu is a five-star luxury hotel and resort
                  in Kathmandu, set on 37 acres of landscaped grounds and created
                  in the traditional Newari style of Nepalese architecture. This
                  beautiful hotel and resort is located on the road to the
                  Boudhanath Stupa: the most holy of all Tibetan Buddhist shrines
                  outside of Tibet and a UNESCO World Heritage Site located within
                  a five-minute walk from the hotel. The hotel is just four
                  kilometres (2.4 miles) from the Tribhuvan International Airport
                  and six kilometres (3.7 miles) from the city center of
                  Kathmandu.
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="cyan darken-2" dark>Details</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row> -->
            <h4 class="headline md-10 text-center">
                List of Employees in Hotel
            </h4>
            <v-row>

                <v-col cols="12" md="6" class="mt-15" style="margin-top:40px">
                    <v-card>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar color="purple">
                                    <v-icon dark>{{svgHeading}}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> <strong>Employee Name</strong> </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title class="primary--text"><strong>Types of Positions</strong></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-card style="overflow-y: scroll; max-height: 350px">
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> Ram Maharjan </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Patan, Lalitpur
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title class="primary--text">Restaurant Manager</v-list-item-title>

                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> Sajan Shrestha </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Durbar Marg, Kathmandu
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title class="primary--text">Kitchen Staff</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$1,600 & 3,600</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Sabin Bajracharya</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Lukla - Shyangboche Marg, Khumjung
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title class="primary--text">Room Service</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> Geet Maa </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Pragya Marg, Kathmandu
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title class="primary--text">Waiter/Waitress</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark>{{ svgCustomer }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Mehga Bahadur</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Battisputali, Kathmandu
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title class="primary--text">Kitchen Manager</v-list-item-title>
                                    <!-- <v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>

                        </v-list>
                    </v-card>
                    <!--<button @click="$router.push('/hotelEmployee')" class=" white--text primary align-end" dark style="padding:5px; border-radius:5px; margin-top:10px;">For More Details</button>-->
                </v-col>
                <v-col cols="12" md="6" style=" margin-top: 40px">
                    <v-card>

                        <template>
                            <div id="chart" ml="10">
                                <apexchart type="pie"
                                           width="600"
                                           align="left"
                                           :options="chartsOptions"
                                           :series="series1"></apexchart>
                            </div>
                        </template>
                    </v-card>

                </v-col>

                <!-- <v-col cols="12" md12>
                    <h1 class="headline mb-2 grey--text">Bugets</h1>
                  </v-col> -->
            </v-row>
            <!-- <v-col cols="12" sm="5">
          <v-card
            class="mx-12 rounded-tl-xl rounded-bl-xl rounded-br-xl mt-n10"
          >
            <v-app-bar color="rgba(0,0,0,0)" flat class="ma-8">
              <h5>Your Activity</h5>
              <v-spacer></v-spacer>
              <v-btn color="primary" rounded dark depressed>Week</v-btn>
              <v-btn text>Month</v-btn>
            </v-app-bar>
            <v-progress-circular
              rotate="360"
              size="100"
              width="15"
              value="50"
              color="primary"
              class="mt-n5 ml-5 mb-2"
            >
              50
            </v-progress-circular>
            <v-progress-circular
              rotate="360"
              size="100"
              width="15"
              value="70"
              color="red"
              class="mt-n5 ml-5 mb-2"
            >
              70
            </v-progress-circular>
            <v-progress-circular
              rotate="360"
              size="100"
              width="15"
              value="80"
              color="orange"
              class="mt-n5 ml-5 mb-2"
            >
              80
            </v-progress-circular>
          </v-card>
        </v-col> -->
            <!-- </v-row> -->
        </v-container>
        <v-row class="my-5">
            <!-- <v-col cols="12" sm="6">
              <v-card class="mx-2 rounded-tl-xl rounded-bl-xl rounded-br-xl mt-n10">
                <v-app-bar color="rgba(0,0,0,0)" flat class="ma-8">
                  <h2 class="blue--text">General Report</h2>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" text>
                    2021
                    <v-icon right>{{svgDownArrow}}</v-icon>
                  </v-btn>
                </v-app-bar>
                <template>
                  <v-sparkline
                    :value="value"
                    color="warning"
                    :smooth="radius || false"
                    :padding="padding"
                    :line-width="width"
                    :stroke-linecap="lineCap"
                    :fill="fill"
                    :type="type"
                    :auto-line-width="autoLineWidth"
                    auto-draw
                  >

                  </v-sparkline>


                </template>
              </v-card>


            </v-col> -->
            <hr style="margin-top: 60px; width:100% " />
            <v-col cols="12" md="12">
                <h1 class="headline mb-2 primary--text">Hotel Room</h1>
                <v-card elevation="5">
                    <template>
                        <div id="app">
                            <GChart type="ColumnChart"
                                    :data="RoomData"
                                    :options="chartOptions"
                                    style="height: 350px" />
                        </div>
                    </template>
                </v-card>
                <!--<button @click="$router.push('/availableRoom')" class=" white--text primary align-end" dark style="padding:5px; border-radius:5px; margin-top:10px;">For More Details</button>-->
            </v-col>

            <!-- <v-col cols="12" sm="5">

              <v-card class="mx-12 rounded-tl-xl rounded-bl-xl rounded-br-xl mt-n10">
                <v-app-bar color="rgba(0,0,0,0)" flat class="ma-8">
                  <h5>Your Activity</h5>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" rounded dark depressed>Week</v-btn>
                  <v-btn text>Month</v-btn>
                </v-app-bar>
                <v-progress-circular rotate="360" size="100" width="15" value="50" color="primary" class="mt-n5 ml-5 mb-2">
                  50
                </v-progress-circular>
                <v-progress-circular rotate="360" size="100" width="15" value="70" color="red" class="mt-n5 ml-5 mb-2">
                  70
                </v-progress-circular>
                <v-progress-circular rotate="360" size="100" width="15" value="80" color="orange" class="mt-n5 ml-5 mb-2">
                  80
                </v-progress-circular>
              </v-card>
            </v-col>               -->
        </v-row>
    </v-container>
</template>

<script>
    // import {GChart} from 'vue-google-charts';
    // const gradients = [
    //     ["#222"],
    //     ["#42b3f4"],
    //     ['red','orange','yellow'],
    //     ['purple', 'violet'],
    //     ['#00c6ff','#f0f','#ff0'],
    //     ['#f72047', "#ffd200","#1feaea"],
    // ]

    import { mdiClock, mdiStore, mdiNaturePeople, mdiHead } from "@mdi/js";
    import VueApexCharts from "vue-apexcharts";
    /*import publicAxios from '../../../simple-axios'*/
    // import Vue from 'vue/types/umd';
    // Vue.use(VueApexCharts)

    export default {
        props: [
            "btn-text"
        ],
        data: () => ({

            /*HotelData: [],*/
            // picker:new Date.toISOString().substr(0,10),
            // chartData: [
            //   ["ID","X","Y","In Million"],
            //   ['',80,167,120],
            //   ['',79,136,130],
            //   ['',78,184,50],
            //   ['',72,278,230],
            //   ['',81,200,210],
            //   ['',72,170,100],
            //   ['',68,477,80],
            // ],
            // chartOptions:{
            //   colorAxis: {colors: ["yellow","red"]}
            // },
            chartData: [
                ["Year", "5-star Hotel", "3-star Hotel", "Homestay", "Normal Hotel"],
                ["2014", 1000, 400, 200, 300],
                ["2015", 1170, 460, 250, 200],
                ["2016", 660, 1120, 300, 100],
                ["2017", 1030, 540, 350, 400],
                ["2018", 1025, 530, 302, 500],
                ["2019", 1040, 560, 356, 600],
                ["2020", 2000, 1000, 500, 500],
                ["2021", 5000, 2500, 1000, 450],
            ],
            chartOptions: {
                chart: {
                    title: "Company Performance",
                    subtitle: "Sales, Expenses, and Profit: 2014-2020",
                },
            },
            barData: [
                ["Year", "Sales", "Expenses", "Profit"],
                ["Hyatt Regency", 1000, 400, 200],
                ["Yak & Yeti", 1170, 460, 250],
                ["Godavari Village Resort", 660, 1120, 300],
                ["Hotel Everest View", 1030, 540, 350],
                ["Begnas Lake Resort", 1025, 530, 302],
                ["Hotel Golden Plaza", 1040, 560, 356],
                ["The Dwarika's Hotel", 2000, 1000, 500],
                ["Kathmandu Marriott Hotel", 5000, 2500, 1000],

                // ["Hotel Name", "Rank"],
                // ["Hyatt Regency", 1000, 400, 200],
                // ["Yak & Yeti", 1170, 460, 250],
                // ["Godavari Village Resort", 660, 1120, 300],
                // ["2017", 1030, 540, 350],
                // ["2018", 1025, 530, 302],
                // ["2019", 1040, 560, 356],
                // ["2020", 2000, 1000, 500],
                // ["2021", 5000, 2500, 1000],
            ],
            barsData: [
                ["Months", "Chinese", "Indian", "American", "Japanese", "Russian", "European"],
                ["Jan", 1000, 2000, 500, 1000, 2000, 500],
                ["Feb", 1170, 1500, 600, 1000, 2000, 500],
                ["Mar", 660, 800, 700, 1000, 2000, 500],
                ["Apr", 1030, 1300, 800, 1000, 2000, 500],
                ["May", 1025, 1200, 500, 1000, 2000, 500],
                ["Jun", 1040, 1400, 900, 1000, 2000, 500],
                ["Jul", 2000, 1500, 1000, 1000, 2000, 500],
                ["Aug", 5000, 1100, 200, 1000, 2000, 500],
                ["Sep", 2800, 600, 400, 1000, 2000, 500],
                ["Oct", 8000, 1222, 1000, 1000, 2000, 500],
                ["Nov", 8000, 1222, 1000, 1000, 2000, 500],
                ["Dec", 8000, 1222, 1000, 1000, 2000, 500],

                // ["Hotel Name", "Rank"],
                // ["Hyatt Regency", 1000, 400, 200],
                // ["Yak & Yeti", 1170, 460, 250],
                // ["Godavari Village Resort", 660, 1120, 300],
                // ["2017", 1030, 540, 350],
                // ["2018", 1025, 530, 302],
                // ["2019", 1040, 560, 356],
                // ["2020", 2000, 1000, 500],
                // ["2021", 5000, 2500, 1000],
            ],
            customerData: [
                ["Months", "Customer"],
                ["Jan", 30000],
                ["Feb", 40000],
                ["Mar", 35000],
                ["Apr", 50000],
                ["May", 42000],
                ["Jun", 31000],
                ["Jul", 23000],
                ["Aug", 32000],
                ["Sep", 12000],
                ["Oct", 23000],
                ["Nov", 22200],
                ["Dec", 45030],

            ],

            RoomData: [
                ["Year", " Total Room", "Room Booked"],
                ["Jan", 2000, 1000],
                ["Feb", 2170, 1500],
                ["Mar", 2060, 1800],
                ["Apr", 3030, 2100],
                ["May", 4025, 3200],
                ["Jun", 4040, 3400],
                ["Jul", 5000, 4000],
                ["Aug", 5800, 4100],
                ["Sep", 6800, 3000],
                ["Oct", 7000, 600],
                ["Nov", 6800, 3000],
                ["Dec", 7000, 600],
            ],
            slides: [
                "Online check and payment of official debts",
                "Online check and payment of official ",
                "Online check and payment of ",
                "Online check and payment ",
            ],

            series: [400, 450, 350, 310, 280, 330, 520, 120, 130, 250],
            series1: [50, 30, 35, 20, 12],
            chartsOptions: {
                chart: {
                    width: 800,
                    type: "donut",
                },
                labels: [
                    "Kitchen Staff",
                    "Room Service",
                    "Waiter/Waitress",
                    "Accounting",
                    "Housekeeping",
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 500,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            slide: [
                "../assets/soltihotel.jpg",
                "../assets/hyatthotel.jpg",
                "../assets/YakandYeti.jpg",
            ],
            width: 2,
            radius: 10,
            padding: 8,
            lineCap: "round",
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            fill: false,
            type: "trend",
            autoLineWidth: false,
            svgClock: mdiClock,
            svgStore: mdiStore,
            svgCustomer: mdiNaturePeople,
            svgHeading: mdiHead
        }),
        components: {
            apexchart: VueApexCharts,
        },
        //methods: {
        //    async getHotelData() {
        //        const HotelData = await publicAxios.get('HotelDetails/GetAllHotelDetailsList')
        //        this.HotelData = HotelData
        //        console.log('this.hotelData', this.HotelData)
        //        /*console.log('this.hotelData', this.HotelData)*/
        //    },
        //},
        //created() {
        //    this.getHotelData()
        //}
    };
</script>
<style scoped>
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

    .v-progress-circular {
        margin: 1rem;
    }
</style>
